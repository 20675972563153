export const isString = (i: any): i is string => {
    return typeof i === 'string' || i instanceof String;
}

export const isNumber = (i: any): i is number => {
    return typeof i === 'number' || i instanceof Number;
}

export const clamp = (n: number, min: number, max: number) => {
    return Math.min(Math.max(n, min), max);
};

export const Lerp = (from: number, to: number, time: number) => {
    return from * (1 - time) + to * time;
}

export const PingPong = (start: number, stop: number, dist: number) => {
    var d = stop - start;
    if (Math.floor(Math.abs(dist) / d) % 2 == 0) {
      return start + Math.abs(dist) % d;
    } else {
      return stop - Math.abs(dist) % d;
    }
  }

export function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number,
): (...args: Params) => void {
  let timer: NodeJS.Timeout
  return (...args: Params) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func(...args)
      }, timeout)
  }
}

export function nmod(n: number, m: number) {
  return ((n % m) + m) % m;
}

export function fmod(dividend: number, divisor: number){
  var multiplier = 0;
  
  while(divisor * multiplier < dividend) {
      ++multiplier;
  }

  --multiplier;
  
  return dividend - (divisor * multiplier);
}

export function wrap(kx: number,  kLowerBound: number, kUpperBound: number) {
  const range = kUpperBound - kLowerBound;
  kx = ((kx-kLowerBound) % range);
  if (kx<0) return kUpperBound + 1 + kx;
  else return kLowerBound + kx;
}

export function wrapMax(x: number, max: number)  {
  return fmod(max + fmod(x, max), max);
}

export function wrapMinMax(x, min, max) {
  return min + wrapMax(x - min, max - min);
}

