import * as Msgpack from 'msgpack-lite';
import * as Base2048 from 'base2048';

import { isString } from '../../shared/Help';
import { MLPChoices, ToggleInteger, ToggleKey, TogglePercent, omitDefaults } from '../../shared/PutinChoices';

import { cyrb53 } from '../../shared/Cyrb';
import { LocalNamedPutin, NamedPutin } from './NamedPutin';

let COUNTRY: string
let CITY: string

// FIXME: MAYBE
// This is foul, creating a bunch of asyncs / awaits just to dynamically import these files
// which is necessary for ssr to work (these files use PutinImages whice has a reference to stupid PIXI)

const defaultGlobal = async () => {
    return import('../../shared/DefaultChoices').then((dc)=>{ return dc.defaultGlobalChoices(); })
}

const defaultPutin = async () => {
    return import('../../shared/DefaultChoices').then((dc)=>{ return dc.defaultPutinChoices(); })
}

export function uniencode(obj) {
    return obj ? Base2048.encode(Msgpack.encode(obj)) : null;
}

export function unidecode(str: string) {
    return str ? Msgpack.decode(Base2048.decode(str)) : null;
}

export const CLEAR_CITY_COUNTRY=()=>{
    COUNTRY = null;
    CITY = null;
} 

export const isLocalStorageAvailable = () => {
    var test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

const _saveCityAndCountry = async () => {
    fetch('https://api.ipregistry.co/?key=tryout').then((response) => {
        return response.json();
    }).then((payload) => {
        COUNTRY = payload?.location?.country?.code;
        CITY = payload?.location?.city;
    
        if(!isLocalStorageAvailable()) return;
        
        if(COUNTRY != null && typeof window !== 'undefined') {
            localStorage?.setItem('COUNTRY', COUNTRY);
        }
        
        if(CITY != null && typeof window !== 'undefined') {
            localStorage?.setItem('CITY', CITY);
        }
    })
}

export const GET_COUNTRY = async () => {
    if(COUNTRY == null && typeof window !== 'undefined' && isLocalStorageAvailable()) {
        COUNTRY = localStorage?.getItem('COUNTRY');
    }
    return COUNTRY ? COUNTRY : _saveCityAndCountry().then(()=>COUNTRY);
}

export const GET_CITY = async () => {
    if(CITY == null && typeof window !== 'undefined') {
        CITY = localStorage?.getItem('CITY');
    }
    return CITY ? CITY : _saveCityAndCountry().then(()=>CITY);
}

// make sure country is set early

// GET_COUNTRY(); 
// GET_CITY();

// save: 
//    - spc_hash = hash MLPChoices
//        - send spc_hash: MLPChoices to putin_version_db 
//    - sp = SerializablePutin{name, version, spc_hash}
//    - hash sp (final ID)
//        - send sp_hash: sp to putin_db 

export const ID_PUTIN_URI = (id: string) => {
    return "/putin?" + id;
}

export const NU_ID_PUTIN_URI = (id: string) => {
    // mylittleputin.com/putin/?p=43253&n=
    return "/putin?p=" + id;
}

export const DATA_PUTIN_URI = (json: NamedPutin) => {
    return "/putin?" + (json ? "data=" + encodeURIComponent(uniencode(json)) : "")
} 

export const OPEN_PUTIN = (id: string, newTab = false) => {
    const local = GET_LOCAL_PUTINS()[id] 
    if(local) {
        const w = window.open(DATA_PUTIN_URI(local.putin), newTab ? "_blank" : "_self");
        if(newTab) w?.focus();
        if(!w) window.open(DATA_PUTIN_URI(local.putin), "_self");
    } else {
        const w = window.open(ID_PUTIN_URI(id), newTab ? "_blank" : "_self");
        if(newTab) w?.focus();
        if(!w) window.open(ID_PUTIN_URI(id), "_self");
    }
}

const API_URL = 'https://mlp-sky.bog.workers.dev/'

const VERSION = 1

const test = {
    "name": "Test Name",
    "choices": {
        "putin": {
            "hat": {
                "src": "naruto"
            }
        }
    },
    "country": "CA",
    "city": "Testville",
}

const PUTIN: NamedPutin = {
    choices: {},
    country: "RU",
    name: "Vladimir Putin",
    created: "Oct 07 1952",
}

const kLOCAL_PUTIN = "kLOCAL_PUTIN"
// localStorage.setItem(kLOCAL_PUTIN, null);
const _LOCAL_PUTINS: {[key in string]: LocalNamedPutin} = (isLocalStorageAvailable() ? JSON.parse(localStorage.getItem(kLOCAL_PUTIN)) : null) ?? {}

export const GET_LOCAL_PUTINS = () => {
    return _LOCAL_PUTINS;
}

const _LOCAL_KEY_FOR_NAMED_PUTIN = (putin: NamedPutin) => {
    return "l_" + cyrb53(JSON.stringify({choices: putin.choices, name: putin.name}));
} 

const _SAVE_LOCAL_PUTIN = (putin: NamedPutin, cloud_id: string, gallery: boolean): LocalNamedPutin => {
    if(!isLocalStorageAvailable()) return null;

    const localKey = _LOCAL_KEY_FOR_NAMED_PUTIN(putin)

    const isPutin = (putin.name ?? "").length == 0 && Object.keys(putin.choices).length == 0
    const local = {
        local: isPutin ? "" : localKey, 
        putin: isPutin ? PUTIN: putin, 
        id: cloud_id,
        gallery
    }

    _LOCAL_PUTINS[localKey] = local; localStorage?.setItem(kLOCAL_PUTIN, JSON.stringify(_LOCAL_PUTINS));

    return local
}

 const _UPLOAD_PUTIN = async (putin: NamedPutin): Promise<string> => {
    const body = JSON.stringify(putin);

    var opts = {
        method: 'PUT',      
        'Content-Type': 'text/plain',
        body
    };
    
    const uploaded = await fetch(API_URL, opts).then(function (response) {
        return response.json();
    }).catch((e)=> {
        return null;
    });

    return uploaded.id;
}

export const UPLOAD_PUTIN = async (np: NamedPutin) => {
    const uuid = await _UPLOAD_PUTIN(np)
    return SAVE_LOCAL_PUTIN(np, uuid, true);
}

export const MAKE_NAMED_PUTIN = async (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>, name?: string): Promise<NamedPutin> => {
    const def = {putin: await defaultPutin(), global: await defaultGlobal()}
    const omitted = omitDefaults(choices, def)

    return {
        name,
        choices: omitted,
        // country: COUNTRY,
        // city: CITY,
        version: VERSION,
        created: Date.now()
    }
}

// export const UPDATE_LOCAL_PUTIN = async (putin: NamedPutin, cloud_id: string): Promise<LocalNamedPutin> => {
//     const key = _LOCAL_KEY_FOR_NAMED_PUTIN(putin)
//     const local = _LOCAL_PUTINS[key];
//     if(local) {
//         _SAVE_LOCAL_PUTIN(await MAKE_NAMED_PUTIN(putin.choices, putin.name), cloud_id, local.gallery);
//     }
//     return local
// }

export const SAVE_LOCAL_PUTIN = async (putin: NamedPutin, cloud_id?: string, isLocal?: boolean): Promise<LocalNamedPutin> => {
    const key = _LOCAL_KEY_FOR_NAMED_PUTIN(putin)
    let local = _LOCAL_PUTINS[key];
    return _SAVE_LOCAL_PUTIN(
        await MAKE_NAMED_PUTIN(putin.choices, putin.name), 
        cloud_id, 
        isLocal ?? local?.gallery ?? false
    );
}

export const LOAD_LOCAL_PUTIN = (id: string | NamedPutin): LocalNamedPutin => {
    let local: LocalNamedPutin;
    if(!isString(id)) {
        const data = id;
        const dataID = _LOCAL_KEY_FOR_NAMED_PUTIN(id)
        local = _LOCAL_PUTINS[dataID] ?? {id: null, local: null, putin: data, gallery: false};
    } else {
        local = _LOCAL_PUTINS[id];
    }
    return local ? local : null
}

const LOAD_CLOUD_PUTIN = async (id: string) => {
    return await fetch(API_URL + id, {
        method: 'GET',      
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((resp)=> {
        return resp.json();
    }).catch((e)=> {
        return null;
    })
}

export const GET_PUTIN_JSON = (data: NamedPutin): NamedPutin => {
    
    const isPutin = data?.name == null && (data?.choices == null || Object.values(data.choices).length == 0)

    const putin =  isPutin ? PUTIN : data ? {
        name: data.name,
        // city: data.city ?? PUTIN.name,
        country: data.country,
        choices: data.choices,
        created: data.created,
        version: data.version
    } : null;
    
    return putin;
}

export const LOAD_PUTIN = async (id: string): Promise<LocalNamedPutin> => {
    let data: LocalNamedPutin;

    const local = LOAD_LOCAL_PUTIN(id)
    if(local) { data = local }
    else if(id.length > 0) {
        const cloud_data = await LOAD_CLOUD_PUTIN(id)
        data = {local: null, id, putin: GET_PUTIN_JSON(cloud_data), gallery: false}
    } else {
        data = {local: null, id: null, putin: PUTIN, gallery: false};
    }

    return data;
}