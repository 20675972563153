export function isToggleInteger(object: any ): object is ToggleInteger {
    return object && typeof object === 'object' && 'i' in object && typeof object.i === 'number';
}

export function isTogglePercent(object: any ): object is TogglePercent {
    return object && typeof object === 'object' && 'p' in object && typeof object.p === 'number';
}

export function isToggleKey(object: any ): object is ToggleKey {
    return object && typeof object === 'object' && 'k' in object && typeof object.k === 'string';
}

export function isToggleKeyPooled(object: any ): object is ToggleKeyPooled {
    return object && typeof object === 'object' && 'k' in object && 'pool' in object && typeof object.k === 'string';
}

export function isMatch(object: any ): object is (ToggleMatch<Toggle> ) {
    return object && typeof object === 'object' && 'match' in object;
}

export function isMirror(object: any ): object is ToggleMirror {
    return object && typeof object === 'object' && 'mirror' in object && 'base' in object;
}

export function isClear(object: any ): object is ToggleClear<DefaultedToggle> {
    return object && typeof object === 'object' && 'clear' in object;
}

export function isProcessAll(object: any ): object is ToggleAll {
    return object && typeof object === 'object' && 'all' in object;
}

export function isSpecificValue(object: any ): object is ToggleSpecificValue<Toggle> {
    return object && typeof object === 'object' && 'base' in object && 'value' in object;
}

export function isRun(object: any ): object is ToggleRun<Toggle> {
    return object && typeof object === 'object' && 'base' in object && 'run' in object;
}

export interface TogglePercent {p: number}; 
export interface ToggleInteger {i: number};
export interface ToggleKey {k: string};

export type Toggle = TogglePercent | ToggleInteger | ToggleKey;

export interface DefaultedToggleInteger extends ToggleInteger {default?: ToggleInteger}
export interface DefaultedTogglePercent extends TogglePercent {default?: TogglePercent}

export interface ToggleKeyPooled extends ToggleKey {pool: {[key in string]: any}}
export interface DefaultedToggleKey extends ToggleKey {default?: ToggleKeyPooled,}

export type DefaultedToggle = DefaultedToggleInteger | DefaultedTogglePercent | DefaultedToggleKey;

type Weighted = {weight?: number}

export interface ToggleClear<T extends DefaultedToggle> extends Weighted {
    clear: T[],
}

export interface ToggleMatch<T extends Toggle | ToggleMirror> extends Weighted {
    match: T[],
}

export interface ToggleRun<T extends DefaultedToggle> extends Weighted {
    base: T,
    run: (base: T) => void
}

export interface ToggleSpecificValue<T extends DefaultedToggle> extends Weighted {
    base: T,
    value: T
}

export interface ToggleAll extends Weighted {
    all: ToggleAny[],
}

export interface ToggleMirror extends Weighted {
    base: TogglePercent,
    mirror: TogglePercent,
}

export type ToggleAny = ToggleMatch<Toggle> | ToggleAll | Toggle | ToggleChoices | ToggleMirror | ToggleClear<DefaultedToggle> | ToggleSpecificValue<Toggle> | ToggleRun<Toggle>
export interface ToggleChoices extends Weighted {
    choices: ToggleAny[]
};

export type ChoosePupil<TogInt extends ToggleInteger, TogPer extends TogglePercent, TogKey extends ToggleKey> = {
    scalePercent: TogPer,
    offsetPercent: {x: TogPer, y: TogPer},
    src: TogKey,
    tint: TogInt,
}

export type ChooseEyeUnique<TogInt extends ToggleInteger, TogPer extends TogglePercent, TogKey extends ToggleKey> = {
    closed: TogInt,
    pupil: ChoosePupil<TogInt, TogPer, TogKey>,
    white: {
        src: TogKey
    }
    paint: {
        src: TogKey,
        color: TogInt,
        rotationPercent: TogPer
    },
    brow: {
        piercing: {
            src: TogKey
        }
    },
    squint: TogInt,
}


export type ChooseEyeShared<TogInt extends ToggleInteger, TogPer extends TogglePercent, TogKey extends ToggleKey> = {
    rotationPercent: TogPer,
    scalePercent: {x: TogPer, y: TogPer},
    offsetPercent: {x: TogPer, y: TogPer},

    light: {
        src: TogKey
    }

    lashTop: {
        src: TogKey,
        color: TogInt
    },

    lashBottom: {
        src: TogKey,
        color: TogInt
    },

    brow: {
        pool: TogKey,
        scalePercent: TogPer,
        rotationPercent: TogPer,
        offsetPercent: {x: TogPer, y: TogPer,},
        color: {i: 0}
    },

    eyeshadow1: {
        src: TogKey,
        alpha: TogInt,
        color: TogInt,
        scalePercent: {x: TogPer, y: TogPer,},
    },

    eyeshadow2: {
        src: TogKey,
        alpha: TogInt,
        color: TogInt,
        scalePercent: {x: TogPer, y: TogPer,},
    },
    
    eyeliner1: {
        src: TogKey,
        color: TogInt,
        hide: TogInt // show both, left, right
    },

    eyeliner2: {
        src: TogKey,
        color: TogInt,
        hide: TogInt // show both, left, right
    },

    eyeliner3: {
        src: TogKey,
        color: TogInt,
        hide: TogInt // show both, left, right
    },

    eyeliner4: {
        src: TogKey,
        color: TogInt,
        hide: TogInt // show both, left, right
    },

    left: ChooseEyeUnique<TogInt, TogPer, TogKey>,
    right: ChooseEyeUnique<TogInt, TogPer, TogKey>,


}

export type GlobalChoices<TogInt extends ToggleInteger, TogPer extends TogglePercent, TogKey extends ToggleKey> = {
    
    grain: TogInt,
    vignette: TogInt,
    foreground: {
        src: TogKey,
        color: TogInt,
        position: TogInt,
        scalePercent: TogPer,
        filter: TogInt
    },
    frame: {
        src: TogKey,
        color: TogInt,
        filter: TogInt
    },
    background: {
        choice: TogKey,
        extra: TogKey,
        filter: TogInt,
    },
    putin: {
        filter: TogInt,
        outline: {
            color: TogInt,
            size: TogInt
        },
    },
}

export type Tattoo<TogInt extends ToggleInteger, TogPer extends TogglePercent, TogKey extends ToggleKey> = {
    src: TogKey,
    offsetPercent: {x: TogPer, y: TogPer},
    rotationPercent: TogPer,
    scalePercent: TogPer,
    flip: TogInt
}


export type EyeTattoo<TogInt extends ToggleInteger, TogPer extends TogglePercent, TogKey extends ToggleKey> = {
    src: TogKey,
    scalePercent: TogPer,
}

export interface PutinChoices<TogInt extends ToggleInteger, TogPer extends TogglePercent, TogKey extends ToggleKey> {
    skin: {
        color: TogInt,
        wrinkles: {
            eyes: TogKey,
            forehead: TogKey,
            mouth: TogKey
        },
    },
    crying: {
        inner: TogInt,
        outer: TogInt
    },
    eyes: ChooseEyeShared<TogInt, TogPer, TogKey>,
    horns: {
        frontLeft: {
            src: TogKey,
            color: TogPer,
            scalePercent: TogPer,
            offsetPercent: {x: TogPer, y: TogPer}
            rotationPercent: TogPer,
        },
        frontRight: {
            src: TogKey,
            color: TogPer,
            scalePercent: TogPer,
            offsetPercent: {x: TogPer, y: TogPer}
            rotationPercent: TogPer,
        },
    },
    hat: {
        src: TogKey,
        colorPercent: TogPer,
    },
    cigarette: {
        src: TogKey,
        rotationPercent: TogPer,
        offsetPercent: {x: TogPer},
        scale: {x: TogInt},
        smoke: TogInt,
    },
    ear: {
        src: TogKey,
        scalePercent: {x: TogPer, y: TogPer}
        rotationPercent: TogPer,
        offsetPercent: {y: TogPer,}
        left: {
            earring: {
                lower1: TogKey,
                lower2: TogKey,
                upper1: TogKey,
                upper2: TogKey,
            },
        },
        right: {
            earring: {
                lower1: TogKey,
                lower2: TogKey,
                upper1: TogKey,
                upper2: TogKey,
            }
        }
    },
    scars: {
        scar1: Tattoo<TogInt, TogPer, TogKey>,
        scar2: Tattoo<TogInt, TogPer, TogKey>,
        scar3: Tattoo<TogInt, TogPer, TogKey>,
        scar4: Tattoo<TogInt, TogPer, TogKey>,
    },
    freckles: {
        src: TogKey,
        scalePercent: TogPer
    },
    tattoos: {
        neck1: Tattoo<TogInt, TogPer, TogKey>,
        neck2: Tattoo<TogInt, TogPer, TogKey>,
        neck3: Tattoo<TogInt, TogPer, TogKey>,
        face: {
            eyeLTop: EyeTattoo<TogInt, TogPer, TogKey>,
            eyeLBottom: EyeTattoo<TogInt, TogPer, TogKey>,
            eyeRTop: EyeTattoo<TogInt, TogPer, TogKey>,
            eyeRBottom: EyeTattoo<TogInt, TogPer, TogKey>,
            forehead1: Tattoo<TogInt, TogPer, TogKey>,
            forehead2: Tattoo<TogInt, TogPer, TogKey>,
            forehead3: Tattoo<TogInt, TogPer, TogKey>,
            cheekL: Tattoo<TogInt, TogPer, TogKey>,
            cheekR: Tattoo<TogInt, TogPer, TogKey>,
        }
    }
    cheeks: {
        offsetPercent: {x: TogPer, y: TogPer},
        scalePercent: {x: TogPer, y: TogPer}
        src: TogKey,
        color: TogInt,
        outer: {
            src: TogKey,
            offsetPercent: {x: TogPer, y: TogPer},
        }
        left: {
            paint: {
                src: TogKey,
                color: TogInt,
                offsetPercent: {x: TogPer, y: TogPer},
                scalePercent: TogPer,
                rotationPercent: TogPer
            },
            accessory: {
                src: TogKey,
                offsetPercent: {x: TogPer, y: TogPer},
                scalePercent: TogPer
            }
        },
        right: {
            paint: {
                src: TogKey,
                color: TogInt,
                offsetPercent: {x: TogPer, y: TogPer},
                scalePercent: TogPer,
                rotationPercent: TogPer
            },
            accessory: {
                src: TogKey,
                offsetPercent: {x: TogPer, y: TogPer},
                scalePercent: TogPer
            }
        }
    },
    glasses: {
        src: TogKey,
        offsetPercent: {y: TogPer},
    },
    neck: {
        collar: {
            src: TogKey,
        },
        necklace: {
            src: TogKey,
        },
    }
    forehead: {
        paint: {
            scalePercent: TogPer,
            offsetPercent: {x: TogPer, y: TogPer},
            src: TogKey,
            color: TogInt,
            rotationPercent: TogPer
        },
        accessory: {
            src: TogKey,
            offsetPercent: {x: TogPer, y: TogPer},
            scalePercent: TogPer
        }
    },
    nose: {
        offsetPercent: {y: TogPer}
        scalePercent: {x: TogPer, y: TogPer}
        piercings: {
            septum: {
                src: TogKey,
            },
            ringL: TogKey,
            ringR: TogKey,
        }
        makeup: {
            tip: { 
                src: TogKey,
                color: TogInt
            },
        },
        accessory: {
            src: TogKey,
        }
    },
    mouth: {
        scalePercent: {x: TogPer, y: TogPer}
        offsetPercent: {y: TogPer}
        lipstick: {
            src: TogKey,
            color: TogInt
        },
        lipstick2: {
            src: TogKey,
            color: TogInt
        },
        paint: {
            src: TogKey,
            color: TogInt
        },
        src: TogKey,
    },
    hair: {
        fgSrc: TogKey,
        bgSrc: TogKey,
        color: TogInt, 
        fgFlip: TogInt,
        bgFlip: TogInt,
    },
    moustache: {
        src: TogKey,
        color: TogInt,
        offsetPercent: {y: TogPer},
        scalePercent: {x: TogPer, y: TogPer}
    },
    beard: {
        src: TogKey,
    },
    shoulders: {
        src: TogKey,
        colorPercent: TogPer
    },
    head: {
        scalePercent: {x: TogPer, y: TogPer},
        offsetPercent: {y: TogPer},
        src: TogKey,
    },
    hands: {
        left: {
            src: TogKey,
            offsetPercent: {x: TogPer},
        },
        right: {
            src: TogKey,
            offsetPercent: {x: TogPer},
        }
    }
}

export interface MLPChoices<TogInt extends ToggleInteger, TogPer extends TogglePercent, TogKey extends ToggleKey> {
    putin: PutinChoices<TogInt, TogPer, TogKey>,
    global: GlobalChoices<TogInt, TogPer, TogKey>,
}



export const putinMakeup = (putinChoose: PutinChoices<ToggleInteger, TogglePercent, ToggleKey>): ToggleChoices => {
    return {weight: 10, choices: [
        {choices: [
            putinChoose.cheeks.offsetPercent.x,
            putinChoose.cheeks.offsetPercent.y,
            putinChoose.cheeks.scalePercent.x,
            putinChoose.cheeks.scalePercent.y,
            putinChoose.cheeks.src,
            putinChoose.cheeks.color,
        ]},
        {choices: [
            putinChoose.cheeks.outer.offsetPercent.x,
            putinChoose.cheeks.outer.offsetPercent.y,
            putinChoose.cheeks.outer.src,
        ]},
        {choices: [
            putinChoose.eyes.eyeliner1.color,
            putinChoose.eyes.eyeliner1.src
        ]},
        {choices: [
            putinChoose.eyes.eyeliner2.color,
            putinChoose.eyes.eyeliner2.src
        ]},
        {choices: [
            putinChoose.eyes.eyeliner3.color,
            putinChoose.eyes.eyeliner3.src
        ]},
        {choices: [
            putinChoose.eyes.eyeliner4.color,
            putinChoose.eyes.eyeliner4.src
        ]},
        {choices: [
            putinChoose.eyes.eyeshadow1.color,
            putinChoose.eyes.eyeshadow1.src,
            putinChoose.eyes.eyeshadow1.alpha,
            putinChoose.eyes.eyeshadow1.scalePercent.x,
            putinChoose.eyes.eyeshadow1.scalePercent.y,

        ]},
        {choices: [
            putinChoose.eyes.eyeshadow2.color,
            putinChoose.eyes.eyeshadow2.src,
            putinChoose.eyes.eyeshadow2.alpha,
            putinChoose.eyes.eyeshadow2.scalePercent.x,
            putinChoose.eyes.eyeshadow2.scalePercent.y,

        ]},
        {choices:[
            putinChoose.eyes.lashBottom.color,
            putinChoose.eyes.lashBottom.src
        ]},
        {choices:[
            putinChoose.eyes.lashTop.color,
            putinChoose.eyes.lashTop.src
        ]},
        {choices:[
            putinChoose.mouth.lipstick.color,
            putinChoose.mouth.lipstick.src,
        ]},
        {choices:[
            putinChoose.nose.makeup.tip.src,
            putinChoose.nose.makeup.tip.color,
        ]},
    ]}
}

export const globalRare = (globalChoose: GlobalChoices<ToggleInteger, TogglePercent, ToggleKey>): ToggleAll  => {
    return {weight: 0.1, choices: [
        {weight: 0.5, all:[
            globalChoose.background.choice,
            globalChoose.vignette
        ],},
        {weight: 0.25, all:[
            globalChoose.background.extra,
        ],},
        {weight: 0.5, clear:[
            globalChoose.background.extra,
        ],},
        {weight: 0.5, all:[
            globalChoose.background.filter,
        ],},

        {weight: 0.05, all:[
            globalChoose.putin.filter,
        ],},
        {weight: 0.1, clear:[
            globalChoose.putin.filter,
        ],},

        {weight: 0.02, clear:[
            globalChoose.background.choice,
            globalChoose.background.extra,
        ],},
        {weight: 0.05, clear:[
            globalChoose.background.extra,
            globalChoose.putin.filter,
            globalChoose.background.filter,
        ],},
    ]}  
}

const scarGroup = (putinChoose:PutinChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return [
    putinChoose.scars.scar1.src,
    putinChoose.scars.scar1.scalePercent,
    putinChoose.scars.scar1.offsetPercent.x,
    putinChoose.scars.scar1.offsetPercent.y,
    putinChoose.scars.scar1.rotationPercent,
    putinChoose.scars.scar1.flip,
    
    putinChoose.scars.scar2.src,
    putinChoose.scars.scar2.scalePercent,
    putinChoose.scars.scar2.offsetPercent.x,
    putinChoose.scars.scar2.offsetPercent.y,
    putinChoose.scars.scar2.rotationPercent,
    putinChoose.scars.scar2.flip,
    
    putinChoose.scars.scar3.src,
    putinChoose.scars.scar3.scalePercent,
    putinChoose.scars.scar3.offsetPercent.x,
    putinChoose.scars.scar3.offsetPercent.y,
    putinChoose.scars.scar3.rotationPercent,
    putinChoose.scars.scar3.flip,

    putinChoose.scars.scar4.src,
    putinChoose.scars.scar4.scalePercent,
    putinChoose.scars.scar4.offsetPercent.x,
    putinChoose.scars.scar4.offsetPercent.y,
    putinChoose.scars.scar4.rotationPercent,
    putinChoose.scars.scar4.flip
]}

export const putinBaseOptions = (putinChoose: PutinChoices<ToggleInteger, TogglePercent, ToggleKey>, alive: boolean = true): ToggleAny[] => {

    return [
        putinChoose.moustache.offsetPercent.y,
        putinChoose.moustache.scalePercent.x,
    
        putinChoose.hair.bgSrc,
        putinChoose.hair.bgFlip,
        ...scarGroup(putinChoose),
        putinChoose.ear.offsetPercent.y,
        putinChoose.ear.rotationPercent,
        putinChoose.ear.scalePercent.x,
        putinChoose.ear.scalePercent.y,

        {
            base: putinChoose.eyes.left.pupil.offsetPercent.x,
            mirror: putinChoose.eyes.right.pupil.offsetPercent.x,
        },
        {match: [
            putinChoose.eyes.left.pupil.offsetPercent.x,
            putinChoose.eyes.right.pupil.offsetPercent.x,
        ]},

        {match: [
            putinChoose.eyes.left.pupil.offsetPercent.y,
            putinChoose.eyes.right.pupil.offsetPercent.y,
        ]},
            
        putinChoose.eyes.rotationPercent,
        
        putinChoose.eyes.scalePercent.x,
        putinChoose.eyes.scalePercent.y,
        
        putinChoose.eyes.offsetPercent.x,
        putinChoose.eyes.offsetPercent.y,

        putinChoose.head.scalePercent.x,
        putinChoose.head.scalePercent.y,

        putinChoose.head.offsetPercent.y,
        
        putinChoose.nose.scalePercent.x,
        putinChoose.nose.scalePercent.y,
        
        putinChoose.mouth.offsetPercent.y,
        putinChoose.mouth.scalePercent.x,
        putinChoose.mouth.scalePercent.y,
        putinChoose.mouth.src,

        putinChoose.head.src,
    ]
}


export const putinSomeOptions = (putinChoose: PutinChoices<ToggleInteger, TogglePercent, ToggleKey>, alive: boolean = true): ToggleAny[] => {

    return [
        putinChoose.moustache.offsetPercent.y,
        putinChoose.moustache.scalePercent.x,
    
        putinChoose.hair.bgSrc,
        putinChoose.hair.bgFlip,
        ...scarGroup(putinChoose),
        putinChoose.freckles.scalePercent,
        putinChoose.freckles.src,
        
    ]
}


export const putinBase = (putinChoose: PutinChoices<ToggleInteger, TogglePercent, ToggleKey>, alive: boolean = true): ToggleChoices => {

    return {choices:[
        {weight: 0.1, clear: putinSomeOptions(putinChoose, alive)},
        {weight:0.1, choices: [
            putinChoose.beard.src,
            putinChoose.moustache.src,
            putinChoose.moustache.offsetPercent.y,
            putinChoose.moustache.scalePercent.x,
        ]},
        {weight:0.1, clear: [
            putinChoose.beard.src,
            putinChoose.moustache.src,
            putinChoose.moustache.offsetPercent.y,
            putinChoose.moustache.scalePercent.x,
        ]},
        {weight:0.05, choices: [
            putinChoose.skin.color,
            putinChoose.skin.wrinkles.eyes,
            putinChoose.skin.wrinkles.forehead,
            putinChoose.skin.wrinkles.mouth,
        ]},
        {weight:0.05, choices: [
            putinChoose.skin.wrinkles.eyes,
            putinChoose.skin.wrinkles.forehead,
            putinChoose.skin.wrinkles.mouth,
        ]},
        {weight:0.05, choices: [
            putinChoose.freckles.src,
            putinChoose.freckles.scalePercent,
        ]},
        {weight:0.05, all: [
            putinChoose.hair.fgSrc,
            putinChoose.hair.fgFlip
        ]},
        {weight:0.01, all: [
            putinChoose.hair.bgSrc,
            putinChoose.hair.bgFlip
        ]},
        {weight:0.05, choices: [
            {
                weight: 0.8, all: scarGroup(putinChoose)
            },
            {
                clear: scarGroup(putinChoose)
            },
        ],},
        {weight:0.2, choices: [
            {
                weight: 0.1,
                base: putinChoose.eyes.left.pupil.offsetPercent.x,
                mirror: putinChoose.eyes.right.pupil.offsetPercent.x,
            },
            {match: [
                putinChoose.eyes.left.pupil.offsetPercent.x,
                putinChoose.eyes.right.pupil.offsetPercent.x,
            ]},
        ]},
        {weight:0.5, all:[
            putinChoose.ear.offsetPercent.y,
            putinChoose.ear.rotationPercent,
            putinChoose.ear.scalePercent.x,
            putinChoose.ear.scalePercent.y,

            alive ? {match: [
                putinChoose.eyes.left.pupil.offsetPercent.y,
                putinChoose.eyes.right.pupil.offsetPercent.y,
            ]} : null,
                
            putinChoose.eyes.rotationPercent,
            
            putinChoose.eyes.scalePercent.x,
            putinChoose.eyes.scalePercent.y,
            
            putinChoose.eyes.offsetPercent.x,
            putinChoose.eyes.offsetPercent.y,
        ],},
        {weight:0.5, all:[
            
            putinChoose.head.scalePercent.x,
            putinChoose.head.scalePercent.y,

            putinChoose.head.offsetPercent.y,
            
            putinChoose.nose.scalePercent.x,
            putinChoose.nose.scalePercent.y,
            
            putinChoose.mouth.offsetPercent.y,
            putinChoose.mouth.scalePercent.x,
            putinChoose.mouth.scalePercent.y,
        ]},
        {weight:0.05, all:[
            
            putinChoose.mouth.src,

            putinChoose.head.src,
        ]},
    ]}
}

export const putinBaseT = (putinChoose: PutinChoices<ToggleInteger, TogglePercent, ToggleKey>, alive: boolean = true): ToggleAny => {

    return {weight: 1, all:[
        {all: putinBaseOptions(putinChoose, alive)},
    ]}
}


// merge all items from copy (even ones that aren't in into)
export const merge = (copy: any, into: any) => {
    into = into ?? {}

    if(!copy) return into;
    
    Object.keys(copy).forEach(k => {
        if(into[k] && typeof into[k] === 'object' && typeof copy[k] === 'object') {
            into[k] = merge(copy[k], into[k])
        } else {
            into[k] = copy[k]
        }
    });
    
    return into;
}

// merge only items in into
export const strictMerge = (copy: any, into: any) => {
    into = into ?? {}

    if(!copy) return into;
    
    Object.keys(copy).forEach(k => {
        if(into[k] && typeof into[k] === 'object' && typeof copy[k] === 'object') {
            into[k] = merge(copy[k], into[k])
        } else if(into[k]) {
            into[k] = copy[k]
        }
    });
    
    return into;
}

export const omitDefaults = (copy: any, defaultChoices: MLPChoices<ToggleInteger, TogglePercent, DefaultedToggleKey>) => {
    const defaults = defaultChoices as any ?? {}

    if(!copy) return {};
    
    let omitted: {[key in string]: any} = {}

    Object.keys(defaultChoices).forEach(k => {
        const def = defaults[k]

        if(isTogglePercent(def) && isTogglePercent(copy[k]) && def.p != copy[k].p) {
            omitted[k] = {p: copy[k].p}
        } 
        else if(isToggleInteger(def) && isToggleInteger(copy[k]) && def.i != copy[k].i) {
            omitted[k] = {i: copy[k].i}
        }
        else if(isToggleKey(def) && isToggleKey(copy[k]) && Object.keys(def.default.pool).indexOf(copy[k].k) >= 0 && def.default.k != copy[k].k) {
            omitted[k] = {k: copy[k].k}
        }
        else if(def && !isToggleKey(def) && typeof def === 'object' && typeof copy[k] === 'object') {
            const inner = omitDefaults(copy[k], def)
            if(Object.keys(inner).length != 0) omitted[k] = inner;
        }
    });

    return omitted;
}

export const equals = (copy: any, defaultChoices: MLPChoices<ToggleInteger, TogglePercent, DefaultedToggleKey>) => {
    const defaults = defaultChoices as any ?? {}

    if(!copy) return {};
    
    let eq = true;

    Object.keys(defaultChoices).forEach(k => {
        const def = defaults[k]

        if(typeof def === 'object' && typeof copy[k] === 'object') {
            omitDefaults(copy[k], def)
        } else if(typeof def !== 'object' && typeof copy[k] !== 'object'){
            eq = eq && def == copy[k]
        } else {
            eq = false
            return
        }
    });

    return eq;
}

export function toggleChoose(tChoices: ToggleAny, defaultWeight: number = 1): ToggleAny {

    if(
        tChoices == null || 
        isToggleInteger(tChoices) || 
        isToggleKey(tChoices) || 
        isTogglePercent(tChoices) || 
        isMirror(tChoices) || 
        isMatch(tChoices) || 
        isClear(tChoices) || 
        isSpecificValue(tChoices) || 
        isRun(tChoices) || 
        isProcessAll(tChoices)
    ) {
        return tChoices;
    }

    var i;

    var weights: number[] = [];

    for (i = 0; i < tChoices.choices.length; i++)
        weights[i] = ((tChoices.choices[i] as any).weight ?? defaultWeight) + (weights[i - 1] || 0);
    
    var random = Math.random() * weights[weights.length - 1];
    
    for (i = 0; i < weights.length; i++)
        if (weights[i] > random)
            break;
    
    return tChoices.choices[i];
}

export function handleToggles(choiceType: ToggleAny, handle:(current: DefaultedToggle)=>void, modify = true, skipWeight = false) {

    if(!skipWeight) {
        choiceType = toggleChoose(choiceType);
    }

    if( isToggleInteger(choiceType) || isTogglePercent(choiceType)  || isToggleKey(choiceType) ) {
        handle(choiceType);
    } else if( isMatch(choiceType) ) {
        let toMatchInt: number; let toMatchPerc: number; let toMatchKey: string;

        choiceType.match.forEach((m) => {
            const handleMatch = (match: Toggle, flip: boolean = false) => {
                if(isToggleInteger(match)) {
                    if(toMatchInt == null) { handle(match); toMatchInt = match.i; }
                    if(!modify) return;
                    else { match.i = toMatchInt }
                    match.i = flip ? -match.i : match.i;
                } else if(isTogglePercent(match)) {
                    if(toMatchPerc == null) { handle(match); toMatchPerc = match.p; }
                    if(!modify) return;
                    else { match.p = toMatchPerc }
                    match.p = flip ? 1 - match.p : match.p;
                } else if(isToggleKey(match)) {
                    if(toMatchKey == null) { handle(match); toMatchKey = match.k; }
                    if(!modify) return;
                    else { match.k = toMatchKey }
                }
            }
            handleMatch(m, false);
        });
    } else if( isMirror(choiceType) ) {
        handle(choiceType.base);
        if(!modify) return;
        choiceType.mirror.p = 1 - choiceType.base.p;
    }  else if( isProcessAll(choiceType) ) {
        choiceType?.all.forEach(choiceChoice => {
            handleToggles(choiceChoice, handle, modify);
        });
    } else if( isClear(choiceType) ) {
        choiceType?.clear.forEach(toClear => {
            if(isToggleInteger(toClear)) {
                toClear.i = toClear.default.i
            } else if(isTogglePercent(toClear)) {
                toClear.p = toClear.default.p
            } else if(isToggleKey(toClear)) {
                toClear.k = toClear.default.k
            }
        });
    } else if( isSpecificValue(choiceType) ) {
        // AUDIT: this one will not go through handle, maybe there should be better rules if i add this stuff to anvs
        Object.assign(choiceType.base, choiceType.value);
    }else if( isRun(choiceType) ) {
        handle(choiceType.base)
        choiceType.run(choiceType.base)
    }else if(choiceType != null) { // choose one to handle
        handleToggles(toggleChoose(choiceType), handle, modify);
    }
}

export function handleToggle(choiceArray: ToggleAny, handleInt:(current:number)=>number, handlePercent:(current:number)=>number, handleKey:(current: string, pool: {[key in string]: any})=>string) {
    handleToggles(choiceArray, (t) => {
        if(isToggleInteger(t)) {
            t.i = handleInt(t.i)
        } else if(isTogglePercent(t)) {
            t.p = handlePercent(t.p)
        } else if (isToggleKey(t)) {
            t.k = handleKey(t.k, t.default.pool)
        }
    })
}

export function lookAtToggle(choiceArray: ToggleAny, handleInt:(current:number)=>void, handlePercent:(current:number)=>void, handleKey:(current: string, def: string,)=>void) {
    handleToggles(choiceArray, (t) => {
        if(isToggleInteger(t)) {
            handleInt(t.i)
        } else if(isTogglePercent(t)) {
            handlePercent(t.p)
        } else if(isToggleKey(t)) {
            handleKey(t.k, t.default.k)
        }
    }, false)
}


export function defaultDistance(t: DefaultedToggle, notPercent: number = 0.25): number {
    if(isToggleInteger(t)) {
        return t.i == t.default.i ? 0 : notPercent
    } else if(isTogglePercent(t)) {
        return Math.abs(t.p - t.default.p);
    } else if(isToggleKey(t)) {
        return t.k == t.default.k ? 0 : notPercent
    }
    return 1
}


export function closeToDefault(t: DefaultedToggle, threshold = 0.45): boolean {
    return defaultDistance(t) < threshold
}

const _putinIndividualLike = (putin: PutinChoices<ToggleInteger, TogglePercent, ToggleKey>,) => {
    return (
        closeToDefault(putin.eyes.offsetPercent.x) 
        && closeToDefault(putin.eyes.offsetPercent.y)
        // && closeToDefault(putin.eyes.scalePercent.x) 
        // && closeToDefault(putin.eyes.scalePercent.y)
        && closeToDefault(putin.eyes.rotationPercent)
        && closeToDefault(putin.nose.offsetPercent.y)
        // && closeToDefault(putin.nose.scalePercent.x) 
        // && closeToDefault(putin.nose.scalePercent.y)
        && closeToDefault(putin.mouth.offsetPercent.y)
    )
}

export const putinLike = (putin: PutinChoices<ToggleInteger, TogglePercent, ToggleKey>,) => {
    const distance = defaultDistance(putin.eyes.offsetPercent.x)
        + defaultDistance(putin.eyes.offsetPercent.y)
        + defaultDistance(putin.eyes.scalePercent.x) 
        + defaultDistance(putin.eyes.scalePercent.y)
        + defaultDistance(putin.eyes.rotationPercent) * 2
        + defaultDistance(putin.eyes.brow.offsetPercent.x) / 8
        + defaultDistance(putin.eyes.brow.offsetPercent.y) / 8
        + defaultDistance(putin.eyes.brow.rotationPercent) / 8
        + defaultDistance(putin.eyes.brow.scalePercent) / 8
        + defaultDistance(putin.eyes.brow.pool, 0.25)
        + defaultDistance(putin.nose.offsetPercent.y)
        + defaultDistance(putin.nose.scalePercent.x) 
        + defaultDistance(putin.nose.scalePercent.y)
        + defaultDistance(putin.mouth.src, 0.5)
        + defaultDistance(putin.mouth.offsetPercent.y)
        + defaultDistance(putin.mouth.scalePercent.x)
        + defaultDistance(putin.mouth.scalePercent.y)
        + defaultDistance(putin.beard.src, 2)
        + defaultDistance(putin.moustache.src, 1.5)
        + defaultDistance(putin.hair.bgSrc, 1)
        + defaultDistance(putin.hair.fgSrc, 2)
        + defaultDistance(putin.head.src, 2.5)
        + defaultDistance(putin.head.scalePercent.x) 
        + defaultDistance(putin.head.scalePercent.y)
        + defaultDistance(putin.head.offsetPercent.y)
        + defaultDistance(putin.skin.color, 0.5)
        + defaultDistance(putin.skin.wrinkles.eyes, 0.15)
        + defaultDistance(putin.skin.wrinkles.forehead, 0.15)
        + defaultDistance(putin.skin.wrinkles.mouth, 0.15)
    // console.log(distance)
    return distance < 6 // && _putinIndividualLike(putin)
}